import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currDrop: [],
    selectedCurrency:localStorage.getItem("curr_selected") !== ""
    ? localStorage.getItem("curr_selected")
    : "AED"
}


export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    addedCurrency: (state,{payload}) => {
        state.selectedCurrency = payload.country_curr;
        localStorage.setItem("curr_selected", payload.country_curr);
        state.currDrop = payload.cont_arr;
      
    },
    onlyCurrencyAdded: (state,{payload}) => {
        state.selectedCurrency = payload;
        localStorage.setItem("curr_selected", payload.country_curr);
      
    },
  },
})

// Action creators are generated for each case reducer function
export const { addedCurrency, onlyCurrencyAdded } = currencySlice.actions

export default currencySlice.reducer