import { configureStore } from '@reduxjs/toolkit'
import userSlice from './feature/userSlice'
import wishlistSlice from './feature/wishlist/wishlistSlice'
import { apiSlice } from './rootApi/apiSlice'
import currencySlice from './feature/currency/currencySlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    wishlist:wishlistSlice,
    currency:currencySlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
})