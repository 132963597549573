import { apiSlice } from "../../rootApi/apiSlice";


const productApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductsDisplay: builder.mutation({
      query: (data) => ({
        url: "/get_products_display.php",
        method: "POST",
        body: data,
      }),
    }),
    getProductsBySlug: builder.query({
      query: (id) => ({
        url: `/get_products_data.php?product_slug=` + id,
      }),
    }),
    getProductsByCity: builder.query({
      query: (destId) => ({
        url: `get_products.php?city_id=` + destId,
      }),
    }),
    getProductsByCityLimitedFour: builder.query({
      query: (id) => ({
        url: `/get_products.php?limit=4&city_id=${id}`,
      }),
    }),
    getBanner: builder.query({
      query: (id) => ({
        url: `/get_banner_image.php`,
      }),
    }),
    getFooterLogo: builder.query({
      query: (id) => ({
        url: `/get_logos.php`,
      }),
    }),
    getBannerHighlight: builder.query({
      query: (id) => ({
        url: `/get_banner_highlights.php`,
      }),
    }),
    getSubBanner: builder.query({
      query: (id) => ({
        url: `/get_subbanner_dets.php`,
      }),
    }),
    getCities: builder.query({
      query: (id) => ({
        url: `/get_cities.php`,
      }),
    }),
    
  }),
});

export const {
  useGetSubBannerQuery,
  useGetProductsByCityLimitedFourQuery,
  useGetFooterLogoQuery,
  useGetProductsByCityQuery,
  useGetCitiesQuery,
  useGetProductsDisplayMutation,
  useGetBannerQuery,
  useGetProductsBySlugQuery,
  useGetBannerHighlightQuery
} = productApi;