import React, { useState, useEffect, lazy, Suspense } from "react";
import { Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetCurrencyQuery } from "./redux/feature/currency/currencyApi";
import "./App.css";
import "./Responsive.css";
import AppBar from "./Components/AppBar";
import Footer from "./Components/Footer";
import Loader from "./Loader";
import { useAuth } from "./Contexts/AuthContext";
import BASE_URLS from "./baseUrl";

// Lazy-loaded components
const Home = lazy(() => import("./Components/Home/Home"));
const Promotion = lazy(() => import("./Components/Promotion"));
const AllDestinations = lazy(() => import("./Components/AllDestinations"));
const ThingstoDo = lazy(() => import("./Components/ThingsToDo/ThingToDo"));
const CheckOut = lazy(() => import("./Components/CheckOut/CheckOut"));
const Activity = lazy(() => import("./Components/Activity/Activity"));
const PrivacyPolicy = lazy(() => import("./Components/PrivacyPolicy"));
const CookiesPolicy = lazy(() => import("./Components/CookiesPolicy"));
const TermsOfUse = lazy(() => import("./Components/TermsOfUse"));
const About = lazy(() => import("./Components/About/About"));
const HelpCenter = lazy(() => import("./Components/HelpCenter/HelpCenter"));
const Articles = lazy(() => import("./Components/HelpCenter/Article"));
const ContactUs = lazy(() => import("./Components/Contact/ContactUs"));
const ForgotPassword = lazy(() => import("./Components/ForgotPassword"));
const ResetPassword = lazy(() => import("./Components/resetPassword"));
const BookingConfirmation = lazy(() => import("./Components/CheckOut/BookingConfirmation"));
const BookingSuccess = lazy(() => import("./Components/CheckOut/BookingSuccess"));
const BookingFailure = lazy(() => import("./Components/CheckOut/BookingFailure"));
const PaymentRedirect = lazy(() => import("./Components/CheckOut/PaymentRedirect"));
const Wishlist = lazy(() => import("./Components/UserProfile/Wishlist"));
const SettingsTab = lazy(() => import("./Components/UserProfile/SettingsTab"));
const ProfileTab = lazy(() => import("./Components/UserProfile/ProfileTab"));
const BookingTab = lazy(() => import("./Components/UserProfile/BookingTab"));
const ReviewTab = lazy(() => import("./Components/UserProfile/ReviewTab"));
const OrderDetails = lazy(() => import("./Components/UserProfile/OrderDetails"));
const NotFound = lazy(() => import("./Components/NotFound"));

function App({ user }) {
  const [loading, setLoading] = useState(true);
  const { data } = useGetCurrencyQuery();
  const { isLoggedIn, isLoggedInWithGmail, handleGoogleLogout } = useAuth();
  const state = useSelector(state => state);
  const { currDrop } = useSelector(state => state.currency);
  const navigate = useNavigate();
  const location = useLocation();
  const webUrl = BASE_URLS.webUrl;

  useEffect(() => {
    // Simulate a slight delay before loading the content
    setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 0);
    }, 100);
  }, [location.pathname]);

  return (
    <>
      <AppBar
        user={user}
        isLoggedInWithGmail={isLoggedInWithGmail}
        isLoggedIn={isLoggedIn}
        navigate={navigate}
        handleGoogleLogout={handleGoogleLogout}
      />
      {loading ? (
        <Loader />
      ) : (
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={webUrl} element={<Home />} />
            <Route path={`${webUrl}destinations`} element={<AllDestinations />} />
            <Route path={`${webUrl}things-to-do/:city`} element={<ThingstoDo />} />
            <Route path={`${webUrl}activity/:id`} element={<Activity />} />
            <Route path={`${webUrl}checkout`} element={isLoggedIn ? <CheckOut /> : <Navigate to={webUrl} />} />
            <Route path={`${webUrl}promotions`} element={<Promotion />} />
            <Route path={`${webUrl}privacy-policy`} element={<PrivacyPolicy />} />
            <Route path={`${webUrl}cookie-policy`} element={<CookiesPolicy />} />
            <Route path={`${webUrl}terms-of-use`} element={<TermsOfUse />} />
            <Route path={`${webUrl}about-us`} element={<About />} />
            <Route path={`${webUrl}contact-us`} element={<ContactUs />} />
            <Route path={`${webUrl}help-center`} element={<HelpCenter />} />
            <Route path={`${webUrl}articles/:cat_id`} element={<Articles />} />
            <Route path={`${webUrl}forgot-password`} element={isLoggedIn ? <Navigate to={webUrl} /> : <ForgotPassword />} />
            <Route path={`${webUrl}reset-password/:token`} element={isLoggedIn ? <Navigate to={webUrl} /> : <ResetPassword />} />
            <Route path={`${webUrl}order-summary`} element={isLoggedIn ? <BookingConfirmation /> : <Navigate to={webUrl} />} />
            <Route path={`${webUrl}booking-success`} element={<BookingSuccess />} />
            <Route path={`${webUrl}booking-failure`} element={<BookingFailure />} />
            <Route path={`${webUrl}redirect_url`} element={<PaymentRedirect />} />
            <Route path={`${webUrl}wishlist`} element={isLoggedIn ? <Wishlist /> : <Navigate to={webUrl} />} />
            <Route path={`${webUrl}account/bookings`} element={isLoggedIn ? <BookingTab /> : <Navigate to={webUrl} />} />
            <Route path={`${webUrl}account/reviews`} element={isLoggedIn ? <ReviewTab /> : <Navigate to={webUrl} />} />
            <Route path={`${webUrl}account/profile`} element={isLoggedIn ? <ProfileTab /> : <Navigate to={webUrl} />} />
            <Route path={`${webUrl}account/settings`} element={isLoggedIn ? <SettingsTab /> : <Navigate to={webUrl} />} />
            <Route path={`${webUrl}account/order-details/:orderId`} element={isLoggedIn ? <OrderDetails /> : <Navigate to={webUrl} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      )}
      <Footer />
    </>
  );
}

export default React.memo(App);
