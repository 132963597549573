import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGetFooterLogoQuery } from "../redux/feature/products/productsApi";
import BASE_URLS from '../baseUrl';
import Logo from '../Images/rathin-white-logo.png';
import "bootstrap-icons/font/bootstrap-icons.css";

function Footer() {  
  const currentYear = new Date().getFullYear();
  const webUrl = BASE_URLS.webUrl;
  const { data: addr, isLoading } = useGetFooterLogoQuery();

  return (
    <footer className="footer-style-1">
        <Container>
            <Row className="gy-3 copyright">
                <Col md={6}>
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item"> <Link to={`${webUrl}about-us`}>About Us</Link></li>
                        <li className="list-inline-item"> <Link to={`${webUrl}contact-us`}>Contact Us</Link></li>
                        <li className="list-inline-item"> <Link to={`${webUrl}privacy-policy`}>Privacy Policy</Link></li>
                        <li className="list-inline-item"> <Link to={`${webUrl}cookie-policy`}>Cookie Policy</Link></li>
                        <li className="list-inline-item"> <Link to={`${webUrl}terms-of-use`}>Terms of Use</Link></li>
                    </ul>
                </Col>
                <Col md={4}>
                    <p className="mb-0">©{currentYear} Copyright Rathin Tourism LLC. All Rights Reserved</p>
                </Col>
                <Col md={2} className="footer-links text-right">
                    <div className="social-links d-flex justify-content-center">
                        {addr && !isLoading && (
                            <>
                                {addr.twitter_link && (
                                    <Link to={addr.twitter_link} target="_blank" rel="noopener noreferrer">
                                        <button className="social-media" aria-label="Rathin Tours Twitter">
                                            <i className="bi bi-twitter"></i>
                                        </button>
                                    </Link>
                                )}
                                {addr.fb_link && (
                                    <Link to={addr.fb_link} target="_blank" rel="noopener noreferrer">
                                        <button className="social-media" aria-label="Rathin Tours Facebook">
                                            <i className="bi bi-facebook"></i>
                                        </button>
                                    </Link>
                                )}
                                {addr.insta_link && (
                                    <Link to={addr.insta_link} target="_blank" rel="noopener noreferrer">
                                        <button className="social-media" aria-label="Rathin Tours Instagram">
                                            <i className="bi bi-instagram"></i>
                                        </button>
                                    </Link>
                                )}
                                {addr.li_link && (
                                    <Link to={addr.li_link} target="_blank" rel="noopener noreferrer">
                                        <button className="social-media" aria-label="Rathin Tours LinkedIn">
                                            <i className="bi bi-linkedin"></i>
                                        </button>
                                    </Link>
                                )}
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    </footer>
  );
}

export default Footer;
