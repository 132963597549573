import React, { useState, useContext, useEffect } from "react";
import BASE_URLS from "../baseUrl";
import { toast } from "react-toastify";

const FilterContext = React.createContext();

// Hook method
export function useFilter() {
  return useContext(FilterContext);
}

export function FilterProvider(props) {
  const mainApiUrl = BASE_URLS.main;
  const [price_value, setPriceValue] = useState(0);
  const [category_value_list, setCategoryValueList] = useState([]);
  const [act_value_list, setActValueList] = useState([]);
  const [others_value_list, setOthersValueList] = useState([]);
  const [rating_val, setRatingVal] = useState("");
  const [productsData, setProductsData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [productName, setProductName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [dateVal, setDate] = useState("");

  const [actData, setActivities] = useState([]);
  const [actCount, setActCount] = useState([]);
  const [allData, setAllData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [prodTags, setProdTags] = useState([]);

  useEffect(() => {
    const getActivities = async () => {
      const res = await fetch(`${mainApiUrl}/get_activities.php`);
      const getdata = await res.json();
      if (getdata) {
        setActivities(getdata.arr_act_all);
        setActCount(getdata.count);
      }
    };
    getActivities();
  }, [mainApiUrl]);

  useEffect(() => {
    const getCategory = async () => {
      const res = await fetch(`${mainApiUrl}/get_category.php`);
      const getdata = await res.json();
      if (getdata) {
        setAllData(getdata);
      }
    };
    getCategory();
  }, [mainApiUrl]);

  const [resultProd, setTotProd] = useState([]);
  // useEffect(() => {
  //     const getProducts = async () => {
  //         const res = await fetch(`${mainApiUrl}/get_products.php`);
  //         const getdata = await res.json();
  //         if (getdata.prod_list) {
  //             setTotProd(getdata.prod_list);
  //         }
  //     }
  //     //getProducts();
  // }, [mainApiUrl]);

  // console.log(allData);

  useEffect(() => {
    if (allData.cat_arr) {
      setCatData(allData.cat_arr);
    }
    if (allData.arr_prod_tag) {
      setProdTags(allData.arr_prod_tag);
    }
  }, [allData]);

  // Update postData using useEffect whenever relevant state variables change
  useEffect(() => {
    const filterProducts = async () => {
      // console.log(productName + " - " + locationName + " - " + dateVal);
      const postData = {
        ...(category_value_list.length > 0
          ? { category_id: category_value_list.join(",") }
          : { category_id: "" }),
        ...(others_value_list.length > 0
          ? { others_id: others_value_list.join(",") }
          : { others_id: "" }),
        ...(act_value_list.length > 0
          ? { act_id: act_value_list.join(",") }
          : { act_id: "" }),
        ...(rating_val > 0 && { prod_rating: rating_val }),
        ...(price_value > 0 && { product_sale_price: price_value }),
        ...(selectedOption > 0 && { prod_sort: selectedOption }),
        ...(productName.length > 0
          ? { product_name: productName }
          : { product_name: "" }),
        ...(locationName.length > 0
          ? { location_name: locationName }
          : { location_name: "" }),
        ...(dateVal.length > 0 ? { date: dateVal } : { date: "" }),
      };

      getProductsData(postData);
    };

    filterProducts();
  }, [
    price_value,
    rating_val,
    category_value_list,
    act_value_list,
    others_value_list,
    selectedOption,
    productName,
    dateVal,
    locationName,
  ]);

  const getProductsData = async (filterData) => {
    // try {
      // Send a POST request to your API to add the product to the wishlist
      // const res = await fetch(`${mainApiUrl}/get_products.php`);

    //   const response = await fetch(`${mainApiUrl}/get_products.php`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(filterData),
    //   });
    //   if (response.ok) {
    //     const getdata = await response.json();
    //     setProductsData(getdata);
    //     setTotProd(getdata.prod_list);

    //     // setTotProd(getdata.prod_list);

    //     // Handle success, e.g., show a success message or update UI
    //   } else {
    //     // console.log("error");
    //     // Handle error, e.g., show an error message
    //     toast.error("Failed to add product to wishlist");
    //   }
    // } catch (error) {
    //   // Handle network errors or other exceptions
    //   console.error("Error:", error);
    //   toast.error("An error occurred while listing products", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // }
  };

  const handleFilters = (
    category_id,
    price,
    rating,
    product_sort,
    others_id,
    act_id,
    product_name,
    location_name,
    date
  ) => {
    // console.log(product_name + ' - ' + location_name + ' - ' + date);
    // Setting Price value
    price > 0 && setPriceValue(price);

    // Check if the value is already present in the current array
    if (category_id !== "") {
      if (category_value_list.includes(category_id)) {
        // Filter the value if it's already present
        setCategoryValueList((category_value_list) =>
          category_value_list.filter((value) => value !== category_id)
        );
      } else {
        // Updating the current array
        setCategoryValueList((prev) => [...prev, category_id]);
      }
    }
    if (others_id !== "") {
      if (others_value_list.includes(others_id)) {
        // Filter the value if it's already present
        setOthersValueList((others_value_list) =>
          others_value_list.filter((value) => value !== others_id)
        );
      } else {
        // Updating the current array
        setOthersValueList((prev) => [...prev, others_id]);
      }
    }
    if (act_id !== "") {
      if (act_value_list.includes(act_id)) {
        setActValueList((act_value_list) =>
          act_value_list.filter((value) => value !== act_id)
        );
      } else {
        setActValueList((prev) => [...prev, act_id]);
      }
    }

    rating > 0 && setRatingVal(rating);
    product_sort > 0 && setSelectedOption(product_sort);

    if (
      product_name !== "" &&
      product_name !== undefined &&
      product_name !== null
    ) {
      setProductName(product_name);
    }
    if (product_name !== null && product_name === "") {
      setProductName("");
    }

    if (
      location_name !== "" &&
      location_name !== undefined &&
      location_name !== null
    ) {
      setLocationName(location_name);
    }
    if (location_name !== null && location_name === "") {
      setLocationName("");
    }

    if (date !== "" && date !== undefined && date !== null) {
      setDate(date);
    }
    if (date !== null && date === "") {
      setDate("");
    }
  };

  const value = {
    handleFilters,
    price_value,
    setPriceValue,
    productsData,
    getProductsData,
    selectedOption,
    productName,
    dateVal,
    locationName,
    actData,
    catData,
    prodTags,
    allData,
    resultProd,
    actCount,
    setLocationName,
    act_value_list,
  };
  return (
    <FilterContext.Provider value={value}>
      {props.children}
    </FilterContext.Provider>
  );
}
