import { apiSlice } from "../../rootApi/apiSlice";
import { addedCurrency } from "./currencySlice";

const currencyApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getCurrency: builder.query({
        query: () => ({
          url: "/get_curr.php",
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            console.log("inside api", data);
            dispatch(addedCurrency(data));
          } catch (err) {
            console.error("Error fetching currency data:", err);
          }
        },
      }),
    }),
  });
  
  export const {
    useGetCurrencyQuery
  } = currencyApi;
  