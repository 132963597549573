import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  wishlistItems: [],
}


export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    addedWishList: (state,{payload}) => {
      state.wishlistItems.push(payload);
      
    },
    addedWishListArray: (state,{payload}) => {
      state.wishlistItems =payload;
      
    },
    removeWishList: (state,{payload}) => {
     
      state.wishlistItems = state.wishlistItems.filter(w=>w.product_id !== payload.products_id);
    },
    
  },
})

// Action creators are generated for each case reducer function
export const { addedWishList,removeWishList, addedWishListArray } = wishlistSlice.actions

export default wishlistSlice.reducer